import axios from 'axios';
import AuthService from './auth-header';

// /*dev*/const API_URL = 'http://192.168.1.46:5000/api/';
// /*prod*/ // const API_URL = 'https://ats.sattvahuman.in/api/';

let API_URL = ''
if (process.env.NODE_ENV === "production") {
    API_URL = 'https://ats.sattvahuman.in/api/';
} else {
    API_URL = 'http://192.168.1.46:5000/api/';
}
class mailService {

    async mail(data) {
        try {
          const res = await axios.post(API_URL + 'mail', data,
            {
                headers: AuthService.authHeader()
            });
            console.log(res.data)
            return res.data
        }
        catch(err) {
            console.log(err.response);
            return err.reponse;
        }
    }    

    async massMail(data) {
        try {
          const res = await axios.post(API_URL + 'mass-mail', data,
            {
                headers: AuthService.authHeader()
            });
            //console.log(res.data)
            return res.data
        }
        catch(err) {
            //console.log(err.response);
            return err.response;
        }
    }
    //greievance mail
    async gmail(data) {
        try {
          const res = await axios.post(API_URL + 'gmail', data,
            {
                headers: AuthService.authHeader()
            });
            //console.log(res.data)
            return res.data
        }
        catch(err) {
            //console.log(err.response);
            return err.reponse;
        }
    }
    async sendEmail(data) {
        try {
          const res = await axios.post(API_URL + 'send-mail', data,
            {
                headers: AuthService.authHeader()
            });
            console.log(res)
            return res
        }
        catch(err) {
            //console.log(err.response);
            return err.response;
        }
    }    

}
export default new mailService